import React, { useEffect, useState } from "react";
import {
  Flex,
  Form,
  Row,
  Col,
  Divider,
  Select,
  ColorPicker,
  Button,
  notification,
  Modal,
  Spin,
} from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import StandardLightTheme from "../../Components/StandardLightTheme";
import StandardDarkTheme from "../../Components/StandardDarkTheme";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import CustomDragger from "../../Components/DynamicForm/Components/CustomDragger";
import { configureTheme, getDisplayImage } from "../../helpers/utils";
import { FormStyling } from "../../Common/CommonUiStyles";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAppearance } from "../../Redux/slices/appearance.reducer";
import notFoundImage from "../../Assets/images/file-not-found.svg";

const Loader = styled(Flex)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  display: grid;
`;

const Uiterlijk = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logoDetails, setLogoDetails] = useState({});
  const [faviconDetails, setFaviconDetails] = useState({});
  const [modalFor, setModalFor] = useState(null);
  const [loader, setLoader] = useState(false);
  const appearance = useSelector((state) => state?.appearance);
  const dispatch = useDispatch();

  const updateFilesList = async (data) => {
    if (modalFor === "favicon") {
      setFaviconDetails({
        ...(data?.[0] || {}),
        imageUrl: await getDisplayImage(data?.[0]?._id),
      });
      form.setFieldValue("favicon", data?.[0]?._id);
    } else {
      setLogoDetails({
        ...(data?.[0] || {}),
        imageUrl: await getDisplayImage(data?.[0]?._id),
      });
      form.setFieldValue("logo_company", data?.[0]?._id);
    }
  };

  useEffect(() => {
    getAndSetAppearance();
  }, []);

  const getAndSetAppearance = async () => {
    try {
      setLoader(true);
      if (appearance?.logo_company) {
        const imageUrl = await getDisplayImage(appearance?.logo_company);
        setLogoDetails((prev) => {
          return {
            ...prev,
            imageUrl,
          };
        });
      }
      if (appearance?.favicon) {
        const imageUrl = await getDisplayImage(appearance?.favicon);
        setFaviconDetails((prev) => {
          return {
            ...prev,
            imageUrl,
          };
        });
      }
      await form.setFieldsValue(appearance);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoader(true);
      const resp = await axiosInstance.put(
        Apis.UPDATE_CONFIGURATION({ type: "appearance" }),
        values,
      );
      if (resp.status === 200) {
        notification.success({ message: t("appearance_upadte_success") });
        dispatch(
          setAppearance(
            await configureTheme(
              values,
              appearance?.logo_company === values?.logo_company,
            ),
          ),
        );
      }
    } catch (error) {
      console.log("Error", error);
      notification.error({ message: t("something_went_wrong") });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("appearance")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.UiterlijkIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
        </div>

        <section className="container max-w-4xl mt-9">
          {loader ? (
            <Loader align="center" gap="middle">
              <Spin
                indicator={
                  <LoadingOutlined spin style={{ fontSize: "32px" }} />
                }
                size="default"
              />
            </Loader>
          ) : (
            <FormStyling layout="vertical" form={form}>
              <Row gutter={28} className="pb-4">
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t("project_color")}
                    className="font-bold"
                    name={`project_color`}
                    htmlFor={`project_color`}
                  >
                    <ColorPicker
                      id={`project_color`}
                      showText
                      className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                      format="hex"
                      onChange={(_, hex) => {
                        form.setFieldValue("project_color", hex);
                      }}
                      presets={[
                        {
                          label: t("default_colors"),
                          colors: [
                            "#6644FF",
                            "#0085CA",
                            "#2ECDA7",
                            "#FFC23B",
                            "#FFA439",
                            "#E35169",
                            "#18222F",
                            "#A2B5CD",
                          ],
                        },
                      ]}
                    />
                  </Form.Item>
                  {/* <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("Login & Logo Background")}
                  </span> */}
                </Col>
                {/* <Col xs={24} md={12}>
                <Form.Item
                  label={t("project") + " " + t("color")}
                  className="font-bold"
                >
                  <ColorPicker
                    showText
                    className="px-[10px] py-[10px] w-full min-h-14 rounded-lg font-normal justify-start border-gray-300"
                  />
                  <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("used_icons_msg")}
                  </span>
                </Form.Item>
              </Col> */}
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t("logo_company")}
                    className="font-bold"
                    name={`logo_company`}
                  >
                    <Button
                      type="link"
                      onClick={() => {
                        setIsModalOpen(true), setModalFor("logo_company");
                      }}
                      className="dark:bg-inputField dark:!border-border-theme"
                    >
                      {t("select_file")}
                    </Button>
                  </Form.Item>
                  {logoDetails?.imageUrl && (
                    <>
                      <img
                        src={
                          logoDetails?.imageUrl
                            ? logoDetails?.imageUrl
                            : notFoundImage
                        }
                        alt="Image"
                        className="added-image"
                      />
                      <Button
                        onClick={() => {
                          setLogoDetails({}),
                            form.setFieldValue("logo_company", null);
                        }}
                      >
                        <IconsAll.CloseIconCustom />
                      </Button>
                    </>
                  )}
                  {/* <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("White 40×40 SVG/PNG")}
                  </span> */}
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={t("favicon")}
                    className="font-bold"
                    name={`favicon`}
                  >
                    <Button
                      type="link"
                      onClick={() => {
                        setIsModalOpen(true), setModalFor(`favicon`);
                      }}
                      className="dark:bg-inputField dark:!border-border-theme"
                    >
                      {t("select_file")}
                    </Button>
                  </Form.Item>
                  {faviconDetails?.imageUrl && (
                    <>
                      <img
                        src={
                          faviconDetails?.imageUrl
                            ? faviconDetails?.imageUrl
                            : notFoundImage
                        }
                        alt="Image"
                        className="added-image"
                      />
                      <Button
                        onClick={() => {
                          setFaviconDetails({}),
                            form.setFieldValue("favicon", null);
                        }}
                      >
                        <IconsAll.CloseIconCustom />
                      </Button>
                    </>
                  )}
                  {/* <span className="font-normal text-sm opacity-40 mt-2 block">
                    {t("32×32 ICO")}
                  </span> */}
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item className="font-bold">
                    <h3 className="flex items-center gap-3 text-2xl font-extrabold">
                      <IconsAll.ThemeIcon />
                      {t("theme") + " " + t("setting").toLowerCase()}
                    </h3>
                  </Form.Item>
                  <Divider className="border-1 w-full my-2" />
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("standard") + " " + t("appearance")}
                    className="font-bold"
                  >
                    <Select
                      placeholder="jack"
                      options={[
                        { value: "jack", label: "Jack" },
                        { value: "lucy", label: "Lucy" },
                        { value: "Yiminghe", label: "yiminghe" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <StandardLightTheme />
                </Col>
                {/* <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("light") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col> */}
                <Col className="gutter-row mt-5" span={24}>
                  <StandardDarkTheme />
                </Col>
                {/* <Col className="gutter-row" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("dark") + " " + t("theme") + " " + t("customization")}
                </span>
              </Col>
              <Col className="gutter-row" span={24}>
                <LightThemeCustomization />
              </Col>
              <Col className="gutter-row mt-5" span={24}>
                <span className="flex items-center gap-1 text-base font-extrabold">
                  {t("custom") + " " + t("css")}
                </span>
              </Col>
              <Col className="gutter-row mt-2" span={24}>
                <TextArea
                  rows={4}
                  placeholder="Design is Pending"
                  maxLength={6}
                />
              </Col> */}
              </Row>
            </FormStyling>
          )}
        </section>
      </div>
      <Modal
        title={t("add_logo")}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        okText={t("ok")}
        cancelText={t("cancel")}
      >
        <CustomDragger
          setIsModalOpen={setIsModalOpen}
          // replaceFileId={fileId}
          updateFilesList={updateFilesList}
        />
      </Modal>
      <AppInfo />
    </Flex>
  );
};

export default Uiterlijk;

import React, { useCallback, useEffect, useState } from "react";
import {
  Drawer,
  Dropdown,
  Flex,
  Modal,
  notification,
  Spin,
  Table,
  Tabs,
} from "antd";
import IconsAll from "../../Components/IconsAll";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import CollectieSetup from "../../Components/Drawers/CollectieSetup";
import AppInfo from "../../Components/AppInfo";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { debounce } from "lodash";
import NoData from "../../Components/NoData";
import { PiSmileySad } from "react-icons/pi";
import { CollapsWithLine, TableStriped } from "../../Common/CommonUiStyles";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const TableSort = styled(Table)`
  .ant-table {
    background: none;
    table {
      background-color: transparent;
      border-spacing: 0 1rem;
      .ant-table-row {
        .ant-table-cell {
          padding: 14px 6px;
          background: var(--body-bg);
          font-family: "Sometype Mono", monospace;
          font-weight: 500;
          color: hsla(var(--heading-text-color), 1);
          &:empty {
            display: none;
          }
          &:first-child {
            border-radius: var(--br-12) 0 0 var(--br-12);
            border-left: 1px solid
              ${() =>
                themes[useSelector((state) => state.theme.name)].components
                  .Table.borderColor} !important;
            padding-left: 24px;
          }
          &:last-child {
            border-radius: 0 var(--br-12) var(--br-12) 0;
            border-right: 1px solid
              ${() =>
                themes[useSelector((state) => state.theme.name)].components
                  .Table.borderColor} !important;
            padding-right: 24px;
          }
        }
      }
    }
    .gray-clr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].token.grayClr};
    }
    .ant-table-thead {
      display: none;
    }
    .ant-table-cell {
      border-bottom: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      border-top: 1px solid
        ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .borderColor} !important;
      &.ant-table-column-has-sorters {
        &:hover {
          background-color: ${() =>
            themes[useSelector((state) => state.theme.name)].components.Table
              .sortedBg} !important;
        }
      }
      &.ant-table-column-sort {
        background-color: ${() =>
          themes[useSelector((state) => state.theme.name)].components.Table
            .sortedBg};
      }
    }

    .imgLocationClr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .imgLocationClr};
      margin-left: 10px;
    }
  }
`;

const DrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: unset !important;
    .ant-drawer-header-title {
      .ant-drawer-close {
        width: 56px;
        height: 56px;
        position: absolute;
        left: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 30px;
        background: hsl(var(--color-white));
        border-radius: 100vh;
        box-shadow: 0px 4px 12px 0px rgba(8, 24, 44, 0.16);
        @media screen and (max-width: 991px) {
          left: calc(50% - 28px);
          bottom: 1rem;
          top: auto;
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 0;
    .ant-tabs {
      @media screen and (max-width: 991px) {
        flex-flow: wrap;
      }
      .ant-tabs-nav {
        padding-top: 40px;
        width: min(100%, 200px);
        min-height: 100vh;
        background-color: #f5f6f7;
        @media screen and (max-width: 991px) {
          width: 100%;
          min-height: auto;
        }
        .ant-tabs-nav-wrap {
          &:before,
          &:after,
          .ant-tabs-ink-bar {
            display: none;
          }
          .ant-tabs-nav-list {
            padding-inline: 10px;
            @media screen and (max-width: 991px) {
              flex-direction: row;
              margin-bottom: 1rem;
            }
            .ant-tabs-tab {
              border-radius: 8px;
              margin-top: 5px;
              > div {
                color: #000 !important;
                font-size: 16px;
                font-style: normal;
              }
              &.ant-tabs-tab-active {
                background-color: #ebeced;
              }
              @media screen and (max-width: 991px) {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      .ant-tabs-tabpane {
        padding-left: 0;
      }
    }
  }
`;

const DataModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      key: "sort",
      width: 1,
      fixed: "left",
    },
    {
      key: "icon",
      dataIndex: "icon",
    },
    {
      key: "collection_name",
      dataIndex: "collection_name",
      width: "1px",
      render: (record, rowData) => (
        <Link
          className={rowData?.visible ? "" : "opacity-25"}
          to={`/settings/data-model/${rowData?._id}`}
          // onClick={() => {
          //   dispatch(
          //     setActiveTab({
          //       page: "settings",
          //       tab: "Data Model Change Collection",
          //       redirectionLink: `/settings/data-model-change-collection/${rowData?._id}`,
          //     }),
          //   );
          // }}
        >
          {record}
        </Link>
      ),
    },
    {
      key: "expand",
      dataIndex: "expand",
    },
    {
      key: "view_content",
      dataIndex: "_id",
      render: (record, rowData) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "view",
                  label: (
                    <Flex gap={"8px"}>
                      <IconsAll.ContentIcon strokeColor="#88909A" />
                      {t("view_content")}
                    </Flex>
                  ),
                },
                {
                  key: "visible",
                  label: (
                    <Flex gap={"8px"}>
                      {rowData?.visible ? (
                        <IconsAll.EyeCloseIcon className="text-xs" />
                      ) : (
                        <IconsAll.OpenEyeIcon
                          strokeColor="#88909A"
                          className="text-xs"
                        />
                      )}
                      {t("make_collection") + " "}
                      {rowData?.visible ? t("Hidden") : t("Visible")}
                    </Flex>
                  ),
                },
                {
                  key: "delete",
                  label: (
                    <Flex gap={"8px"}>
                      <IconsAll.TrashIcon className="text-xs" />
                      {t("delete_collection")}
                    </Flex>
                  ),
                },
              ],
              onClick: (e) => {
                if (e.key === "delete") {
                  Modal.confirm({
                    title: t("delete_collection"),
                    content: t("delete_collection_confirm"),
                    icon: null,
                    okText: t("delete_collection"),
                    okType: "primary",
                    cancelText: t("cancel"),
                    maskClosable: true,
                    className: "modal-delete",
                    onOk() {
                      handleCollectionVisibility(record, e.key);
                    },
                  });
                } else if (e.key === "view") {
                  navigate(`/content/${record}`);
                  dispatch(
                    setActiveTab({
                      page: "content",
                      tab: record,
                      redirectionLink: `/content/${record}`,
                    }),
                  );
                } else if (e.key === "visible") {
                  handleCollectionVisibility(record, e.key, rowData?.visible);
                }
              },
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <IconsAll.MoreIcon />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const handleCollectionVisibility = async (id, type, visible) => {
    if (type === "delete") {
      try {
        const resp = await axiosInstance.delete(Apis.DELETE_A_SCHEMA(id));
        if (resp.status === 200) {
          notification.success({ message: t("collection_delete_success") });
          init();
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const resp = await axiosInstance.patch(
          Apis.UPDATE_SCHEMA_VISIBILITY(id),
          {
            visible: !visible,
          },
        );
        if (resp.status === 200) {
          notification.success({ message: t("visibility_update_success") });
          setDataSource((prev) => {
            return prev.map((item) =>
              item._id === id ? { ...item, visible: !visible } : item,
            );
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async (search) => {
    setLoading(true);
    try {
      const resp = await axiosInstance.get(
        Apis.GET_ALL_SCHEMAS({ system: true, filter: search }),
      );
      if (resp.status === 200) setDataSource(resp.data.payload.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedInit = useCallback(
    debounce((search) => init(search), 1000),
    [],
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  // const text = `it can be found as a welcome guest in many households across the world.`;

  // const items = [
  //   {
  //     key: "1",
  //     label: t("system") + " " + t("collections"),
  //     children: <p>{text}</p>,
  //   },
  // ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerItems = [
    {
      key: "1",
      label: t("collection") + " " + t("setup"),
      children: (
        <CollectieSetup setIsDrawerOpen={setIsDrawerOpen} init={init} />
      ),
      closable: true,
      closeIcon: <IconsAll.BlogIcon />,
    },
    // {
    //   key: "2",
    //   label: t("optional") + " " + t("fields").toLowerCase(),
    //   children: <OptioneleVelden />,
    // },
  ];

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("data_model")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.DataModalIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTermPlaceholder={t("search")}
            // folderButton={true}
            addButton={true}
            addDisabled={loading}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            debouncedInit={debouncedInit}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tabs
              defaultActiveKey="1"
              items={drawerItems}
              tabPosition={"left"}
            />
          </DrawerStyle>
        </div>

        <section className="container max-w-4xl mt-9">
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              // rowKey array
              items={dataSource
                ?.filter((d) => !d?.system_default)
                .map((i) => i.key)}
              strategy={verticalListSortingStrategy}
            >
              <TableSort
                pagination={false}
                components={{
                  body: {
                    row: Row,
                  },
                }}
                columns={columns}
                rowKey={(record) => record.collection_id}
                dataSource={dataSource?.filter((d) => !d?.system_default)}
                locale={{
                  emptyText: (
                    <NoData
                      icon={<PiSmileySad size={44} />}
                      title={t("no_data_found")}
                    />
                  ),
                }}
                loading={{
                  spinning: loading,
                  indicator: (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 36, color: "#1890ff" }}
                          spin
                        />
                      }
                    />
                  ),
                }}
              />
            </SortableContext>
          </DndContext>
          {!loading && (
            <CollapsWithLine
              ghost
              items={[
                {
                  key: "1",
                  label: (
                    <span className="collapse-title">
                      {t("system_collections")}
                    </span>
                  ),
                  children: (
                    <TableStriped
                      pagination={false}
                      showHeader={false}
                      columns={columns?.filter(
                        (c) => c?.key === "collection_name",
                      )}
                      rowKey={(record) => record.collection_id}
                      dataSource={dataSource?.filter((d) => d?.system_default)}
                      locale={{
                        emptyText: (
                          <NoData
                            icon={<PiSmileySad size={44} />}
                            title={t("no_data_found")}
                          />
                        ),
                      }}
                      loading={{
                        spinning: loading,
                        indicator: (
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 36, color: "#1890ff" }}
                                spin
                              />
                            }
                          />
                        ),
                      }}
                    />
                  ),
                },
              ]}
            />
          )}
        </section>
      </div>
      <AppInfo />
    </Flex>
  );
};

export default DataModal;

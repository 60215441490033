import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  content: "",
  users: "",
  settings: "",
  adminSettings: "",
};

const activeTabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      const { page, tab, redirectionLink } = action.payload;
      state[page] = {
        currentTab: tab,
        redirectionLink,
      };
    },
  },
});

export const { setActiveTab } = activeTabSlice.actions;
export default activeTabSlice.reducer;

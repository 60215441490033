import React, { useCallback, useEffect, useState } from "react";
import { Flex, Spin, Table } from "antd";
import IconsAll from "../../../Components/IconsAll";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import NoData from "../../../Components/NoData";
import { themes } from "../../../config";
import { useSelector } from "react-redux";
import { PiSmileySad } from "react-icons/pi";
import { LoadingOutlined } from "@ant-design/icons";
import Apis from "../../../helpers/Apis";
import axiosInstance from "../../../helpers/axios";
import withRouter from "../../../Common/withRouter";
import { debounce } from "lodash";
import { usePagination } from "../../../helpers/custom-hooks/usePagination";

const TableStripe = styled(Table)`
  .ant-table-container {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }
  .ant-table {
    background-color: ${() =>
      themes[useSelector((state) => state.theme.name)].components.Table
        .tableBg};
    border-radius: 0;
    table {
      background-color: transparent;
      thead tr th {
        background-color: hsla(var(--table-row-strips), 0.48);
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background-color: hsla(var(--table-row-strips), 0.48);
          }
        }
      }
    }
    .gray-clr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].token.grayClr};
    }
    .ant-table-cell {
      border-bottom: 1px solid var(--input-field-border) !important;
      border-top: none !important;
      border-radius: 0;
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
    }

    .imgLocationClr {
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Table
          .imgLocationClr};
      margin-left: 10px;
    }
  }
`;

const List = ({ router }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );

  const columns = [
    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
    },
  ];

  const getConfiguration = async (
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        Apis.GET_CONFIGURATION({
          filter: search,
          pagination: true,
          page,
          limit,
        }),
      );
      if (response.status === 200) {
        setConfigurations(response?.data?.payload?.data);
        updatePagination(
          "totalItems",
          response.data?.payload?.data?.meta?.total_found || 0,
        );
        updatePagination("currentPage", page);
        updatePagination("pageSize", limit);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const debouncedInit = useCallback(
    debounce((search, page) => getConfiguration(search, page), 1000),
    [],
  );

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <Flex className="w-full">
      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("configuration")}
            pageSubTitle={t("setting")}
            pageIcon={true}
            pageInnerIcon={<IconsAll.RequestFeatureIcon />}
            backButton={false}
          />
          <RightSIdeTitlePart
            isFilterShow={true}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchTermPlaceholder={t("search_in_configuration")}
            addButton={true}
            handleAddClick={() =>
              router.navigate(`/settings/configuration/add`)
            }
            debouncedInit={debouncedInit}
          />
        </div>

        <section className="mt-9">
          <TableStripe
            onRow={(i) => ({
              onClick: () =>
                router.navigate(`/settings/configuration/${i?.type}`),
            })}
            columns={columns}
            dataSource={configurations}
            pagination={{
              pageSizeOptions: [10, 25, 50],
              current: pagination.currentPage,
              pageSize: pagination.pageSize,
              total: pagination.totalItems,
              position: ["bottomRight"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
              onChange: async (page, size) => {
                page !== pagination.currentPage &&
                  (await getConfiguration(searchTerm, page, size));
              },
              onShowSizeChange: async (page, size) => {
                await getConfiguration(searchTerm, page, size);
              },
            }}
            rowKey={(record) => record?._id}
            scroll={{
              x: 300,
            }}
            locale={{
              emptyText: (
                <NoData
                  icon={<PiSmileySad size={44} />}
                  title={t("no_data_found")}
                />
              ),
            }}
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 36, color: "#1890ff" }}
                      spin
                    />
                  }
                />
              ),
            }}
          />
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(List);

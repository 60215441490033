import Title from "antd/es/typography/Title";
import React from "react";
import IconsAll from "../IconsAll";
import styled from "styled-components";
import {
  Button,
  Flex,
  Form,
  Row,
  Typography,
  Col,
  Input,
  Divider,
  notification,
} from "antd";
import { GrLinkNext } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { themes } from "../../config";
import { useSelector } from "react-redux";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { InvertedCheckBox } from "../../Common/CommonUiStyles";
const { Text } = Typography;

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${() =>
    themes[useSelector((state) => state.theme.name)].components
      .HeaderButtonsStyle.backButtonBg};
  color: ${() =>
    themes[useSelector((state) => state.theme.name)].components
      .HeaderButtonsStyle.backButtonClr};
`;

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonBg};
    color: ${() =>
      themes[useSelector((state) => state.theme.name)].components
        .HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components
          .HeaderButtonsStyle.addButtonHoverClr} !important;
    }
  }
`;

const CollectieSetup = ({ setIsDrawerOpen, init }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleAddCollection = async () => {
    let isError = false;
    try {
      const values = await form.validateFields(); // Validate form fields
      const resp = await axiosInstance.post(Apis.ADD_A_SCHEMA(), {
        collection_name: values.collection_name,
        visible: true,
        fields: [
          {
            field: "_id",
            path: "_id",
            type: "ObjectId",
            include: true,
            field_type: "Single",
            meta: {
              interface: "none",
            },
            schema_definition: {
              is_primary: true,
            },
          },
          {
            field: "status",
            path: "status",
            type: "Boolean",
            include: !!values.status,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
          {
            field: "sort",
            path: "sort",
            type: "Boolean",
            include: !!values.sort,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
          {
            field: "created_at",
            path: "created_at",
            type: "Date",
            include: !!values.created_at,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
          {
            field: "created_by",
            path: "created_by",
            type: "String",
            include: !!values.created_by,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
          {
            field: "updated_at",
            path: "updated_at",
            type: "Date",
            include: !!values.updated_at,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
          {
            field: "updated_by",
            path: "updated_by",
            type: "String",
            include: !!values.updated_by,
            field_type: "Single",
            meta: {
              interface: "none",
            },
          },
        ]
          .filter((item) => !!item.include)
          ?.map((item, i) => {
            return { ...item, meta: { ...item?.meta, sort: i + 1 } };
          }),
      });
      if (resp.status === 200) {
        notification.success({ message: t("collection_add_success") });
      }
      await form.resetFields();
    } catch (error) {
      console.log("Error", error);
      notification.error({ message: t("something_went_wrong") });
      isError = true;
      return;
    } finally {
      if (!isError) {
        setIsDrawerOpen(false);
        init();
      }
    }
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/[^a-z0-9_ ]/g, "");
    newValue = newValue.replace(/ /g, "_");
    form.setFieldValue("collection_name", newValue);
  };

  return (
    <div className="p-10">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title
          className="flex items-center gap-3 !text-2xl !font-extrabold"
          level={3}
        >
          <TItleIconStyle>
            <IconsAll.ContentIcon strokeColor="#0085CA" />
          </TItleIconStyle>
          {t("create_new_collection")}
        </Title>
        <HeaderButton
          shape="circle"
          icon={<GrLinkNext size={24} />}
          className="btn add-button"
          onClick={handleAddCollection}
        ></HeaderButton>
      </Flex>
      <Form layout="vertical" className="mt-6" form={form}>
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              label={t("name")}
              name="collection_name"
              className="font-bold mb-0"
              rules={[
                {
                  required: true,
                  message: t("collection_case_sensitive"),
                },
                {
                  max: 50,
                },
              ]}
            >
              <Input
                type="text"
                onChange={handleChange}
                placeholder={t("enter_data_model_name")}
                className="min-h-14 rounded-xl placeholder:font-normal px-6"
              />
            </Form.Item>
            <Text className="font-normal text-gray-400 text-sm mt-2 block">
              {t("collection_case_sensitive")}
            </Text>
          </Col>
          <Col className="gutter-row" xs={24}>
            <Divider className="my-6" />
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("status")}
              className="font-bold"
              name={`status`}
              valuePropName="checked"
            >
              <InvertedCheckBox>Status</InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("to_sort")}
              className="font-bold"
              name={`sort`}
              valuePropName="checked"
            >
              <InvertedCheckBox>Sort</InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("created") + " " + t("on")}
              className="font-bold"
              name={`created_at`}
              valuePropName="checked"
            >
              <InvertedCheckBox>Date Created</InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("created") + " " + t("by")}
              className="font-bold"
              name={`created_by`}
              valuePropName="checked"
            >
              <InvertedCheckBox>User Created</InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("updated") + " " + t("on")}
              className="font-bold"
              name={`updated_at`}
              valuePropName="checked"
            >
              <InvertedCheckBox>Date Updated</InvertedCheckBox>
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label={t("updated") + " " + t("by")}
              className="font-bold"
              name={`updated_by`}
              valuePropName="checked"
            >
              <InvertedCheckBox>User Updated</InvertedCheckBox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CollectieSetup;

import React, { useEffect, useId, useState } from "react";
import { Form, Row, Col, Flex, Input, Button, notification, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { BsInfoCircle } from "react-icons/bs";
import LeftSideTitlePart from "../../HeaderParts/LeftSideTitlePart";
import IconsAll from "../../IconsAll";
import RightSIdeTitlePart from "../../HeaderParts/RightSIdeTitlePart";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { IoLanguage } from "react-icons/io5";
import { DndContext } from "@dnd-kit/core";
import { TableSort } from "../../../Pages/Instellingen/ChangeDataModalCollection";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AiOutlineClose } from "react-icons/ai";

import {
  DrawerStyle,
  FormStyling,
  InvertedCheckBox,
} from "../../../Common/CommonUiStyles";
import CreateChoices from "../CreateChoices";
import {
  capitalizeAndFormat,
  hasNewFieldFunc,
  TableRow,
} from "../../../helpers/utils";
import { LoadingOutlined } from "@ant-design/icons";

const UpdateField = ({
  activeItem,
  setIsDrawerOpen,
  dataSource,
  setActiveItem,
  init,
}) => {
  const { t } = useTranslation();
  const id = useId();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [translationChoices, setTranslationChoices] = useState([]);
  const [activeChoice, setActiveChoice] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setFormData();
  }, [activeItem?._id]);

  const setFormData = async () => {
    await form.setFieldsValue({
      required: !!activeItem?.meta?.required,
      readonly: !!activeItem?.meta?.readonly,
      hidden: !!activeItem?.meta?.hidden,
      note: activeItem?.meta?.note,
    });
    if (
      activeItem?.meta?.translations &&
      activeItem?.meta?.translations?.length
    )
      setTranslationChoices(
        activeItem?.meta?.translations?.map((t) => {
          return {
            label: t?.translation,
            value: t?.language,
          };
        }),
      );
  };

  useEffect(() => {
    setTranslationChoices([]);
  }, [activeItem?._id]);

  const onDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i?.key === active?.id);
        const overIndex = previous?.findIndex((i) => i?.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      const values = await form.validateFields();
      const finalValues = {
        ...activeItem,
        meta: {
          ...activeItem?.meta,
          required: values.required,
          readonly: values.readonly,
          hidden: values.hidden,
          note: values?.note,
          translations: translationChoices?.map((t) => {
            return { translation: t?.label, language: t?.value };
          }),
        },
      };
      const { _id, ...payload } = finalValues;
      delete payload?.__v;

      const resp = await axiosInstance.put(Apis.UPDATE_A_FIELD(_id), payload);
      if (resp.status === 200) {
        setIsDrawerOpen(false);
        setActiveItem(null);
        init();
        notification.success({ message: t("field_update_success") });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      key: "sort",
      width: 50,
    },
    {
      dataIndex: "label",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      align: "left",
      width: "auto",
      render: (record, rowData, index) => {
        return (
          <div
            onClick={() => {
              setActiveChoice({ ...rowData, index });
              setOpen(true);
            }}
          >
            {record}
          </div>
        );
      },
    },
    {
      dataIndex: "value",
      key: "delete",
      width: 50,
      render: (record) => {
        return (
          <div
            onClick={() =>
              setTranslationChoices((prev) => {
                return prev?.filter((t) => t?.value !== record);
              })
            }
          >
            <AiOutlineClose size={24} />
          </div>
        );
      },
    },
  ];

  return (
    <Flex wrap="wrap" align="center" justify="space-between">
      <LeftSideTitlePart
        pageTitle={
          capitalizeAndFormat(activeItem?.meta?.interface) +
          " " +
          capitalizeAndFormat(activeItem?.type) +
          " (" +
          capitalizeAndFormat(dataSource?.collection_name) +
          ")"
        }
        pageIcon={true}
        pageInnerIcon={<IconsAll.ContentIcon strokeColor={"#3586c7"} />}
      />
      <RightSIdeTitlePart
        showButton={true}
        handleShowClick={handleSubmit}
        showDisabled={loader}
      />
      {loader ? (
        <Spin
          spinning={loader}
          fullscreen
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
                color: "#fff",
              }}
              spin
            />
          }
        />
      ) : (
        <FormStyling
          form={form}
          layout="vertical"
          initialValues={{
            type: "String",
            icon_left: "Icon 1",
            icon_right: "Icon 1",
          }}
          className="mt-4"
        >
          <Row gutter={28}>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("readonly")}
                htmlFor={`${id}-Readonly`}
                className="font-bold"
                name={`readonly`}
                valuePropName="checked"
              >
                <InvertedCheckBox id={`${id}-Readonly`}>
                  {t("disable_editing_value")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("required")}
                htmlFor={`${id}-required`}
                className="font-bold"
                name={`required`}
                valuePropName={"checked"}
              >
                <InvertedCheckBox id={`${id}-required`}>
                  {t("require_value_to_be_set_on_creation")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("hidden")}
                htmlFor={`${id}-hidden`}
                className="font-bold"
                name={`hidden`}
                valuePropName={"checked"}
              >
                <InvertedCheckBox id={`${id}-hidden`}>
                  {t("hidden_on_detail")}
                </InvertedCheckBox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={24}>
              <Form.Item
                label={t("note")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`note`}
              >
                <Input
                  placeholder={t("add_a_helpful_note_for_users")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={24}>
              <Form.Item
                label={t("field_name_translations")}
                htmlFor={`${id}-new-field`}
                className="font-bold"
              >
                {!translationChoices?.length &&
                  hasNewFieldFunc(
                    <BsInfoCircle size={20} />,
                    <p className="font-normal">{t("no_items")}</p>,
                  )}
                <Button
                  className="rounded-3xl  border border-solid border-grey-full bg-grey-full text-black px-5 py-3 mt-4 font-medium h-auto"
                  onClick={() => setOpen(true)}
                >
                  {t("create_new")}
                </Button>
              </Form.Item>
              {translationChoices?.length > 0 && (
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    items={translationChoices?.map((t) => t?.label)}
                    strategy={verticalListSortingStrategy}
                  >
                    <TableSort
                      style={{ width: "100%" }}
                      pagination={false}
                      components={{
                        body: {
                          row: TableRow,
                        },
                      }}
                      rowKey={(record) =>
                        record?.label + record?.value + new Date().toISOString()
                      }
                      columns={columns}
                      dataSource={translationChoices}
                      scroll={{
                        x: 300,
                      }}
                    />
                  </SortableContext>
                </DndContext>
              )}
            </Col>
          </Row>
          <DrawerStyle
            width={870}
            destroyOnClose
            open={open}
            onClose={() => {
              setOpen(false);
              setActiveChoice(null);
            }}
          >
            <CreateChoices
              setChoices={setTranslationChoices}
              setOpen={setOpen}
              choiceType={"translation"}
              activeChoice={activeChoice}
            />
          </DrawerStyle>
        </FormStyling>
      )}
    </Flex>
  );
};

export default UpdateField;

import { Button, Flex, Form, Input, Space } from "antd";
import React from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import withRouter from "../../../Common/withRouter";
import { PlusOutlined } from "@ant-design/icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";

const Add = ({ router }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const formData = await form.validateFields();

      const object = formData?.items?.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      await axiosInstance.post(Apis.ADD_CONFIGURATION(), object);
      router.navigate("/settings/configuration");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
          <div
            onClick={() => {
              router.navigate(`/settings/configuration`);
            }}
          >
            <LeftSideTitlePart
              pageTitle={`${t("creating_item_in")} ${t("configuration")}`}
              pageSubTitle={t("configuration")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
          />
        </div>

        <section className="container max-w-4xl">
          <Form form={form}>
            <Form.List name="items" initialValue={[{ key: "type", value: "" }]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "key"]}
                          key={`${field.key}_key`}
                          rules={[
                            {
                              required: true,
                              message: t("please_enter_the_key"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("key")}
                            className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal`}
                            disabled={field.key === 0}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "value"]}
                          key={`${field.key}_value`}
                          rules={[
                            {
                              required: true,
                              message: t("please_enter_the_value"),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t("value")}
                            className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal`}
                          />
                        </Form.Item>
                        {field.key !== 0 && (
                          <RiDeleteBin6Line
                            onClick={() => remove(field.name)}
                            size={18}
                            className={`btn delete-button cursor-pointer`}
                          />
                        )}
                      </Space>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("add_item")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(Add);

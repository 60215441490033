import { Col, Flex, Form, Input, Row, Button, notification, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";
import IconsAll from "../../Components/IconsAll";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { LoadingOutlined } from "@ant-design/icons";
import { TItleIconStyle } from "../../Common/CommonUiStyles";
import { useTranslation } from "react-i18next";

const HeaderButton = styled(Button)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &.add-button {
    background: ${({ theme }) =>
      theme.components.HeaderButtonsStyle.addButtonBg};
    color: ${({ theme }) => theme.components.HeaderButtonsStyle.addButtonClr};

    &:hover {
      background: ${({ theme }) =>
        theme.components.HeaderButtonsStyle.addButtonHoverBg} !important;
      color: ${({ theme }) =>
        theme.components.HeaderButtonsStyle.addButtonHoverClr} !important;
    }
  }
`;

const AddRole = ({ setIsDrawerOpen, init }) => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    setLoader(true);
    const values = await form.getFieldsValue();
    const formattedValues = {
      name: values.name,
    };

    try {
      const resp = await axiosInstance.post(Apis.ADD_A_ROLE(), {
        name: formattedValues.name,
        permissions: [],
      });
      if (resp.status === 200)
        notification.success({ message: t("role_add_success") });
      await form.resetFields();
      setIsDrawerOpen(false);
      await init();
    } catch (error) {
      console.log("Error", error);
    }
    setLoader(false);
  };

  return (
    <div className="relative">
      <Flex wrap="wrap" align="center" justify="space-between">
        <Title
          className="flex items-center gap-3 !text-2xl !font-extrabold"
          level={3}
        >
          <TItleIconStyle>
            <IconsAll.LinkIcon strokeColor="#0085CA" />
          </TItleIconStyle>
          {t("add_role")}
        </Title>
      </Flex>

      {loader && (
        <Spin
          spinning={loader}
          fullscreen
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
                color: "#fff",
              }}
              spin
            />
          }
        ></Spin>
      )}
      {!loader && (
        <Form layout="vertical" form={form}>
          <HeaderButton
            shape="circle"
            icon={<BsCheckLg size={24} />}
            className="btn add-button absolute top-0 right-0"
            onClick={handleSubmit}
            disabled={loader}
          />
          <Row gutter={28}>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item label="Role" name="name" className="font-bold">
                <Input
                  disabled={loader}
                  type="text"
                  placeholder={t("enter_the_role")}
                  className="min-h-14 rounded-xl placeholder:font-normal px-6"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default AddRole;

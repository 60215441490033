import React, { useEffect, useRef, useState } from "react";
import { Flex, Card, Menu, Image } from "antd";
import styled from "styled-components";
import signalIcon from "../Assets/images/Signals/good.svg";
import { Link } from "react-router-dom";
import NoxVersion from "../Assets/images/Icon/Side_bar_icon/nox-version.svg";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../Redux/slices/activeTab.reducer";
import { themes } from "../config";
import { store } from "../Redux/store";
import useScreenWidth from "../helpers/custom-hooks/useScreenWidth";
import { tabletBreakpoint } from "../helpers/utils";
import Backdrop from "../Layouts/Backdrop";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";

// Card Style
export const SubMenuHeaderStyle = styled(Card)`
  background-color: var(--heading-user-name-bg);
  width: 100%;
  min-height: calc(
    ${() =>
        themes[useSelector((state) => state.theme.name)].companyLogoHeight}px *
      4
  );
  border: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding-inline-start: 10px;

  .ant-card-body {
    padding: 0;

    .company-info-main {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 15px;
    }

    .company-info {
      > * {
        margin-bottom: 0;
      }

      .company-info-title {
        font-size: 14px;
        font-weight: 700;
        font-family: ${() =>
          themes[useSelector((state) => state.theme.name)].token.fontFamily};
        color: hsl(var(--heading-text-color));
        line-height: 1;
      }

      p {
        font-size: 14px;
        font-weight: 300;
        color: ${() =>
          themes[useSelector((state) => state.theme.name)].token.grayClr};
      }
    }
  }
`;

export const SubMenuListStyle = styled(Menu)`
  background-color: var(--simplebar-active-bg);
  padding-left: 5px;
  border-inline-end: 1px solid transparent !important;
  height: calc(100vh - 102px);
  overflow-y: auto;

  .ant-menu-item {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    .ant-menu-item-icon {
      flex-shrink: 0;
    }
    .ant-menu-title-content {
      text-overflow: unset !important;
      width: 100%;
      a {
        color: hsl(var(--heading-text-color));
        font-size: 16px;
        font-weight: 300;
      }
    }

    &.ant-menu-item-selected {
      background-color: rgba(225, 227, 230, 0.24);
      border: 1px solid rgba(255, 255, 255, 0.48);
      color: ${() =>
        themes[useSelector((state) => state.theme.name)].components.Menu
          .itemBg};
    }
  }

  .ant-menu-item-divider {
    margin: 16px 24px;
    border-color: var(--input-field-border);
  }
`;

export const SidebarContainer = styled(Flex)`
  background-color: var(--simplebar-active-bg);

  .resize-handle {
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    right: 0;
    cursor: ew-resize;
    background-color: transparent;
  }

  .resize-handle:hover {
    background-color: rgba(0, 0, 0, 0.2);
    width: 4px;
  }
`;

function getItem(label, key, icon, children, theme, type) {
  return {
    key,
    icon,
    children,
    label,
    theme,
    type,
  };
}

const SubMenu = ({
  items,
  isSubMenuLogo,
  isSubMenuVersion,
  currentPage,
  openKeys,
  onOpenChange,
  defaultOpenKeys,
}) => {
  const containerRef = useRef(null);
  const activeTab = useSelector((state) => state.activeTab[currentPage]);
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth();
  const isTabletOrSmaller = screenWidth <= tabletBreakpoint;
  const [showSubMenu, setShowSubMenu] = useState(!isTabletOrSmaller);
  const { selectedFolderData } = useSelector((state) => state.media);

  useEffect(() => {
    isTabletOrSmaller && setShowSubMenu((prev) => !prev);
  }, [selectedFolderData]);

  function getItems(items) {
    let parsedItem = [];
    items?.forEach((item) => {
      parsedItem.push(
        getItem(
          currentPage === "media" ? (
            item?.name
          ) : (
            <Link to={item?.link}>{item?.name}</Link>
          ),
          currentPage === "media" ? item?.key : item?.name,
          item?.icon,
          ...(item?.children ? [item.children] : []),
        ),
      );
      if (item?.divider === true) {
        parsedItem.push({
          type: "divider",
        });
      }
    });
    return parsedItem;
  }

  const handleMenuClick = (key) => {
    isTabletOrSmaller && setShowSubMenu(false);
    if (items.find((i) => i.name === key)?.link) {
      dispatch(
        setActiveTab({
          page: currentPage,
          tab: key,
          redirectionLink: items.find((i) => i.name === key).link,
        }),
      );
    }
  };

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = containerRef.current.offsetWidth;
    const handleMouseMove = (e) => {
      const diffInX = e.clientX - startX;
      const newWidth = startWidth + diffInX;
      if (newWidth >= 200 && newWidth <= 600) {
        containerRef.current.style.width = `${newWidth}px`;
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const user = store.getState()?.user?.userProfile;

  return showSubMenu ? (
    <SidebarContainer vertical>
      <div
        ref={containerRef}
        className={`w-[260px] top-0 bottom-0 z-50 lg:min-h-screen modal-sidebar-media ${isTabletOrSmaller ? "fixed" : "sticky"}`}
      >
        {isSubMenuLogo ? (
          <SubMenuHeaderStyle>
            <Flex gap={12} className="company-info-main">
              <img src={signalIcon} size={24} alt="nox" />
              <div className="company-info">
                <h3 className="company-info-title">{user?.tenant?.name}</h3>
                {user?.tenant?.primary_domain && (
                  <p>Website: {user?.tenant?.primary_domain}</p>
                )}
                {/* <p>Website: nowonline.nl</p> */}
              </div>
            </Flex>
          </SubMenuHeaderStyle>
        ) : null}
        <SubMenuListStyle
          items={getItems(items)}
          onClick={({ key }) => handleMenuClick(key)}
          selectedKeys={[activeTab?.currentTab]}
          defaultOpenKeys={defaultOpenKeys}
          mode={items?.some((item) => item?.children) ? "inline" : undefined}
          {...(currentPage === "media"
            ? { openKeys: openKeys, onOpenChange }
            : {})}
          className="custom-scrollbar pr-0"
        />
        {isSubMenuVersion ? (
          <Flex className="px-6 py-3 custom-mt-auto bg-[var(--simplebar-active-bg)]">
            <Image preview={false} src={NoxVersion} alt="" width={21} />
            <span className="align-bottom mt-[-7px] text-gray-400 ms-2">
              v1.1.0
            </span>
          </Flex>
        ) : null}
        {currentPage === "media" && (
          <div className="resize-handle" onMouseDown={handleMouseDown}></div>
        )}
        {isTabletOrSmaller && (
          <button
            className="fixed top-[16px] left-[310px] z-10 w-7 h-7 bg-[--logo-bg] flex items-center justify-center rounded-full"
            onClick={() => setShowSubMenu(false)}
          >
            <IoMdClose size={18} className="text-white rotate-180" />
          </button>
        )}
      </div>
      {isTabletOrSmaller && <Backdrop onClick={() => setShowSubMenu(false)} />}
    </SidebarContainer>
  ) : (
    <button
      className="fixed top-1 z-10 w-10 h-10 bg-[--logo-bg] flex items-center justify-center rounded-e-md"
      onClick={() => setShowSubMenu(true)}
    >
      <CgMenuRightAlt size={26} className="text-white rotate-180" />
    </button>
  );
};

export default SubMenu;

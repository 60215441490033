import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * Configures i18n for React with `react-i18next`, supporting "system" and "tenant" namespaces.
 * Sets "system" as the default namespace and disables escaping of special characters for flexible interpolation.
 * Provides global multilingual support across the app.
 */

i18n.use(initReactI18next).init({
  ns: ["system", "tenant"],
  defaultNS: "system",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

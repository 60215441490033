import { Button, Flex, Form, Input, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import LeftSideTitlePart from "../../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../../Components/HeaderParts/RightSIdeTitlePart";
import { useTranslation } from "react-i18next";
import withRouter from "../../../Common/withRouter";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import axiosInstance from "../../../helpers/axios";
import Apis from "../../../helpers/Apis";
import { useParams } from "react-router-dom";

const EditConfiguration = ({ router }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const getConfiguration = async (type) => {
    setLoading(true);
    try {
      let response = await axiosInstance.get(Apis.GET_CONFIGURATION({ type }));
      response = response?.data?.payload?.data?.[0];
      delete response?._id;

      const arrayOfObjects = Object.entries(response).map(([key, value]) => ({
        key,
        value,
      }));

      form.setFieldsValue({ items: arrayOfObjects });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = await form.getFieldsValue(true);

      const object = formData?.items?.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      delete object?.type;

      await axiosInstance.put(
        Apis.UPDATE_CONFIGURATION({ type: params?.field }),
        object,
      );
      router.navigate("/settings/configuration");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConfiguration(params?.field);
  }, []);

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part">
        <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
          <div
            onClick={() => {
              router.navigate(`/settings/configuration`);
            }}
          >
            <LeftSideTitlePart
              pageTitle={`${t("creating_item_in")} ${t("configuration")}`}
              pageSubTitle={t("configuration")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
          />
        </div>

        <section className="container max-w-4xl">
          {!loading ? (
            <Form form={form}>
              <Form.List name="items" initialValue={[]}>
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        return (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "key"]}
                              key={`${field.key}_key`}
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_the_key"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("key")}
                                className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal`}
                                disabled={field.key === 0}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              key={`${field.key}_value`}
                              rules={[
                                {
                                  required: true,
                                  message: t("please_enter_the_value"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("value")}
                                className={`min-h-14 rounded-xl placeholder:font-normal px-3 font-normal`}
                              />
                            </Form.Item>
                            {field.key !== 0 && (
                              <RiDeleteBin6Line
                                onClick={() => remove(field.name)}
                                size={18}
                                className="btn delete-button cursor-pointer"
                              />
                            )}
                          </Space>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {t("add_item")}
                        </Button>
                      </Form.Item>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          ) : (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 36, color: "#1890ff" }}
                  spin
                />
              }
            />
          )}
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(EditConfiguration);

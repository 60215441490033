import React, { useState, useEffect, useId } from "react";
import { Form, Row, Flex, notification, Spin, Col, Input, Select } from "antd";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import withRouter from "../../Common/withRouter";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import axiosInstance from "../../helpers/axios";
import Apis from "../../helpers/Apis";
import { setActiveTab } from "../../Redux/slices/activeTab.reducer";
import { LoadingOutlined } from "@ant-design/icons";
import useLanguage from "../../helpers/custom-hooks/useLanguage";
import TextArea from "antd/es/input/TextArea";
import { queryClient } from "../..";
import { useTranslation } from "react-i18next";

const FormStyling = styled(Form)`
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
`;

const AddVertalingen = ({
  router,
  setIsTranslationDrawerOpen,
  type,
  getTranslations,
}) => {
  const path = location.pathname.split("/");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const id = useId();
  const languageOptions = useLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    if (type !== "drawer" && path[3] !== "add") {
      init();
    }
  }, []);

  const init = async () => {
    setLoader(true);
    try {
      const resp = await axiosInstance.get(
        Apis.GET_SINGLE_TRANSLATION(path[3]),
      );
      if (resp.status === 200) {
        await form.setFieldsValue(resp.data.payload.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const resp = await axiosInstance.post(
        Apis.UPSERT_TRANSLATIONS(type === "drawer" ? "add" : path[3]),
        values,
      );
      if (resp.status === 200) {
        notification.success({ message: t("translation_add_success") });
        if (type === "drawer") {
          setIsTranslationDrawerOpen(false);
          getTranslations();
        } else {
          router.navigate(`/settings/vertalingen`),
            dispatch(
              setActiveTab({
                page: path[1],
                tab: "Vertalingen",
                redirectionLink: `/settings/vertalingen`,
              }),
            );
        }
        queryClient.invalidateQueries({
          queryKey: ["groupedTranslations", values.language],
          exact: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Flex className="w-full main-content-wrap">
      <div className="px-4 xl:px-16 w-full left-part ">
        <div className="flex flex-wrap md:items-center justify-between mt-9 mb-11">
          <div
            onClick={() => {
              router.navigate(`/settings/vertalingen`),
                dispatch(
                  setActiveTab({
                    page: path[1],
                    tab: "Vertalingen",
                    redirectionLink: `/settings/vertalingen`,
                  }),
                );
            }}
          >
            <LeftSideTitlePart
              pageTitle={t("create_custom_translation")}
              backButton={true}
            />
          </div>
          <RightSIdeTitlePart
            showButton={true}
            handleShowClick={handleSubmit}
          />
        </div>

        <section className="container max-w-4xl">
          {loader ? (
            <Spin
              indicator={<LoadingOutlined spin style={{ fontSize: "32px" }} />}
              size="default"
            />
          ) : (
            <FormStyling layout="vertical" form={form}>
              <Row gutter={16}>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("key")}
                    name="key"
                    className="font-bold"
                    htmlFor={`${id}-key`}
                    rules={[
                      {
                        required: true,
                        message: t("please_enter_the_key"),
                      },
                      {
                        max: 50,
                      },
                    ]}
                  >
                    <Input
                      className="min-h-14 rounded-xl placeholder:font-normal px-6"
                      placeholder={t("enter_key")}
                      id={`${id}-key`}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} md={12}>
                  <Form.Item
                    label={t("language")}
                    className="font-bold"
                    htmlFor={`${id}-language`}
                    name={`language`}
                    rules={[
                      {
                        required: true,
                        message: t("please_choose_a_language"),
                      },
                    ]}
                  >
                    <Select
                      id={`${id}-language`}
                      options={languageOptions}
                      showSearch
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24}>
                  <Form.Item
                    label={t("value")}
                    name="value"
                    className="font-bold"
                    htmlFor={`${id}-value`}
                    rules={[
                      { required: true, message: t("please_enter_the_value") },
                    ]}
                  >
                    <TextArea
                      className="min-h-14 rounded-xl placeholder:font-normal px-6"
                      placeholder={t("enter_value")}
                      id={`${id}-value`}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormStyling>
          )}
        </section>
      </div>
    </Flex>
  );
};

export default withRouter(AddVertalingen);

import { Col, Form, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import LeftSideTitlePart from "../../Components/HeaderParts/LeftSideTitlePart";
import TenantListTable from "./TenantListTable";
import Tenant from "./Tenant";
import IconsAll from "../../Components/IconsAll";
import RightSIdeTitlePart from "../../Components/HeaderParts/RightSIdeTitlePart";
import Apis from "../../helpers/Apis";
import axiosInstance from "../../helpers/axios";
import { useTranslation } from "react-i18next";
import AppInfo from "../../Components/AppInfo";
import { DrawerStyle } from "../../Common/CommonUiStyles";
import { usePagination } from "../../helpers/custom-hooks/usePagination";
import { debounce } from "lodash";

const TenantList = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [activeTenant, setActiveTenant] = useState(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { pagination, updatePagination } = usePagination(
    1,
    10,
    "createdAt",
    true,
  );

  useEffect(() => {
    init();
  }, []);

  const init = async (
    search = "",
    page = pagination.currentPage,
    limit = pagination.pageSize,
  ) => {
    const resp = await axiosInstance.get(
      Apis.GET_ALL_TENANTS({
        filter: search,
        page,
        limit,
      }),
    );
    if (resp.status === 200) {
      setTenants(resp?.data?.payload?.data || []);
      updatePagination(
        "totalItems",
        resp?.data?.payload?.meta?.total_found || 0,
      );
      updatePagination("currentPage", page);
      updatePagination("pageSize", limit);
    }
  };

  const debouncedInit = useCallback(
    debounce((search, page) => init(search, page), 1000),
    [],
  );

  return (
    <Row className="main-content-wrap">
      <Col className="px-8 xl:px-16 left-part">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            isFilterShow
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchTermPlaceholder={t("search_in_tenants")}
            debouncedInit={debouncedInit}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            closeIcon={<IconsAll.CloseIconCustom />}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
            pagination={pagination}
            init={init}
            searchTerm={searchTerm}
          />
        </section>
      </Col>
      <Col className="right-part">
        <AppInfo />
      </Col>
    </Row>
    /* <Flex className="w-full">

      <div className="px-4 xl:px-16 w-full">
        <div className="flex flex-wrap md:items-center md:justify-between mt-9 mb-11">
          <LeftSideTitlePart
            pageTitle={t("users")}
            pageIcon={false}
            backButton={false}
          />
          <RightSIdeTitlePart
            addButton={true}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
          <DrawerStyle
            width={870}
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false), setActiveTenant(null), form.resetFields();
            }}
            closeIcon={<IconsAll.CloseIconCustom />}
          >
            <Tenant
              form={form}
              setIsDrawerOpen={setIsDrawerOpen}
              setActiveTenant={setActiveTenant}
              init={init}
              activeTenant={activeTenant}
            />
          </DrawerStyle>
        </div>
        <section className="mt-9">
          <TenantListTable
            tenants={tenants}
            setIsDrawerOpen={setIsDrawerOpen}
            setActiveTenant={setActiveTenant}
          />
        </section>
      </div>
      <AppInfo />
    </Flex> */
  );
};

export default TenantList;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const appearanceSlice = createSlice({
  name: "appearance",
  initialState,
  reducers: {
    setAppearance: (state, action) => action.payload,
  },
});

export const { setAppearance } = appearanceSlice.actions;
export default appearanceSlice.reducer;

/* eslint-disable react/react-in-jsx-scope */
import { Navigate } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import UserProfile from "../Pages/Authentication/UserProfile";
import Content from "../Pages/Content/Content";
import Media from "../Pages/MediaLibrary/Media";
import Help from "../Pages/Help/Help";
import Instellingen from "../Pages/Instellingen/Instellingen";
import Gebruikers from "../Pages/Gebruikers/Gebruikers";
import TenantList from "../Pages/Tenant/TenantList";
import AdminSettings from "../Pages/Admin/Settings/AdminSettings";

const authProtectedRoutes = [
  // ======= Dashboard =========
  { path: "*", component: <Navigate to="/dashboard" /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/dashboard", component: <Dashboard /> },

  // ======= Content =========
  {
    path: "/content/:table?/:action?/:id?",
    exact: true,
    component: <Content />,
  },

  // ======= Users =========
  { path: "/users/:pages?", exact: true, component: <Gebruikers /> },
  {
    path: "/admin/settings/:pages?/:field?",
    exact: true,
    component: <AdminSettings />,
  },

  // ======= Media library =========
  { path: "/media-library/:folderId?", exact: true, component: <Media /> },
  {
    path: "/media-library/file/:fileId/edit",
    exact: true,
    component: <Media />,
  },

  // ======= App Guide / help =========
  { path: "/help", exact: true, component: <Help /> },

  // ======= Settings =========
  {
    path: "/settings/:pages?/:field?",
    exact: true,
    component: <Instellingen />,
  },

  // ======= Tenant List =========
  { path: "/tenant-list", exact: true, component: <TenantList /> },

  // user profile
  { path: "/user-profile", component: <UserProfile /> },
];

const publicRoutes = [
  // Authentication
];

export { publicRoutes, authProtectedRoutes };

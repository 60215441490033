import React, { useEffect, useId } from "react";
import { IoLanguage } from "react-icons/io5";
import {
  Col,
  ColorPicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import LeftSideTitlePart from "../HeaderParts/LeftSideTitlePart";
import RightSIdeTitlePart from "../HeaderParts/RightSIdeTitlePart";
import IconsAll from "../IconsAll";
import useLanguage from "../../helpers/custom-hooks/useLanguage";
import { FormStyling } from "../../Common/CommonUiStyles";

const TItleIconStyle = styled(Typography)`
  min-width: 56px !important;
  width: 56px !important;
  height: 56px;
  border-radius: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateChoices = ({ setChoices, setOpen, choiceType, activeChoice }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const id = useId();
  useEffect(() => {
    if (activeChoice) {
      setFormData();
    }
  }, [activeChoice]);

  const setFormData = async () => {
    await form.setFieldsValue(activeChoice);
  };
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setChoices((prev) => {
        if (
          prev?.some(
            (item, i) =>
              item?.value === values?.value && i !== activeChoice?.index,
          )
        ) {
          notification.error({ message: t("duplicate_value_error") });
          return prev;
        }

        if (activeChoice) {
          return prev.map((item) => {
            return item?.label === activeChoice?.label ? values : item;
          });
        }
        return [...prev, values];
      });
      setOpen(false);
      await form.resetFields();
    } catch (error) {
      notification.error({ message: t("something_went_wrong") });
    }
  };

  const generateChoiceForm = () => {
    switch (choiceType) {
      case "dropdown_multiple":
      case "dropdown":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("icon")}
                htmlFor={`${id}-icon`}
                className="font-bold"
                name={`icon`}
              >
                <Select
                  size="large"
                  placeholder="Icon 1"
                  style={{ flex: 1, height: "56px" }}
                  id={`${id}-icon`}
                  options={[
                    {
                      value: "Icon 1",
                      label: "Icon 1",
                    },
                    {
                      value: "Icon 2",
                      label: "Icon 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("color")}
                htmlFor={`${id}-color`}
                className="font-bold"
                name={`color`}
              >
                <ColorPicker
                  id={`${id}-color`}
                  showText
                  className="w-full justify-start px-3 py-[14.08px] font-normal rounded-xl"
                  format="hex"
                  onChange={(_, hex) => form.setFieldsValue({ color: hex })}
                />
              </Form.Item>
            </Col>
          </>
        );
      case "checkboxes":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          </>
        );
      case "radio":
        return (
          <>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("text")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_text")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <Form.Item
                label={t("value")}
                className="font-bold"
                htmlFor={`${id}-template`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("enter_a_value")}
                  id={`${id}-template`}
                  className="min-h-14 rounded-xl font-normal px-6"
                  suffix={<IoLanguage size={20} color="gray" />}
                />
              </Form.Item>
            </Col>
          </>
        );
      case "translation":
        return (
          <>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("language")}
                className="font-bold"
                htmlFor={`${id}-language`}
                name={`value`}
                rules={[{ required: true }]}
              >
                <Select
                  id={`${id}-language`}
                  options={useLanguage()}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item
                label={t("translation")}
                className="font-bold"
                htmlFor={`${id}-translation`}
                name={`label`}
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder={t("enter_a_translation")}
                  id={`${id}-translation`}
                  className="min-h-14 rounded-xl font-normal px-6"
                />
              </Form.Item>
            </Col>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className="flex flex-wrap md:items-center md:justify-between pb-[24px]">
        <LeftSideTitlePart
          pageTitle={
            <TItleIconStyle>
              <IconsAll.ContentIcon strokeColor="#0085CA" />
            </TItleIconStyle>
          }
        />
        <RightSIdeTitlePart showButton handleShowClick={handleSubmit} />
      </div>
      <FormStyling layout="vertical" className="" form={form}>
        <Row gutter={28}>{generateChoiceForm()}</Row>
      </FormStyling>
    </>
  );
};

export default CreateChoices;

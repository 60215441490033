export default {
  GET_ALL_COLLECTION_NAMES: () => `/noxp/collection`,
  GET_COLLECTION_RECORD_BY_CODE: ({ code }) => `/noxp/collection/${code}`,
  GET_PROJECTION_SCHEMA: ({
    page = 1,
    limit = 10,
    sortBy = "createdAt",
    descending = true,
    schemas = "",
    pagination = true,
  }) =>
    `/noxp/projection_schema?page=${page}&limit=${limit}&sortBy=${sortBy}&descending=${descending}&pagination=${pagination}&schemas=${schemas}`,
  GET_PROJECTION_SCHEMA_BY_CODE: ({ schema = "", isOnlySchema = false }) =>
    `/noxp/projection_schema/${schema}?isOnlySchema=${isOnlySchema}`,
  UPDATE_LISTING_PROJECTION_SCHEMA: ({ code }) =>
    `/noxp/projection_schema/${code}`,
  GET_ROLES: ({
    page = 1,
    limit = 10,
    filter = "",
    sortBy = "createdAt",
    descending = true,
  }) =>
    `/role?page=${page}&limit=${limit}&filter=${filter}&sortBy=${sortBy}&descending=${descending}`,
  GET_ROLE_DETAIL: (id) => `/role/${id}`,
  UPDATE_ROLE: (role_id) => `/role/${role_id}`,
  //Tenant Api calls
  ADD_A_TENANT: () => `/api/v1/tenant`,
  UPDATE_A_TENANT: (tenantID) => `/api/v1/tenant/${tenantID}`,
  GET_ALL_TENANTS: ({
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
    filter = "",
  }) =>
    `/api/v1/tenant?filter=${filter}&limit=${limit}&page=${page}&descending=${descending}&sortBy=${sortBy}`,
  LOGIN: () => `api/v1/auth/retrieve/login-prompt`,
  LOGOUT: () => `api/v1/auth/logout`,
  ADD_A_SCHEMA: () => `/api/v1/schema`,
  GET_ALL_SCHEMAS: ({
    page = 1,
    limit = 30,
    filter = "",
    sortBy = "createdAt",
    descending = true,
    system = false,
  }) =>
    `/api/v1/schema?page=${page}&limit=${limit}&filter=${filter}&sortBy=${sortBy}&descending=${descending}&system=${system}`,
  GET_A_SCHEMA: (collection_id, populated) =>
    `/api/v1/schema/${collection_id}?populated=${populated}`,
  UPDATE_SCHEMA_VISIBILITY: (collection_id) =>
    `/api/v1/schema/${collection_id}`,
  DELETE_A_SCHEMA: (collection_id) => `/api/v1/schema/${collection_id}`,
  ADD_A_ROLE: () => `/api/v1/role`,
  GET_ALL_ROLES: () => `/api/v1/role`,
  GET_A_ROLE: (role_id) => `/api/v1/role/${role_id}`,
  UPDATE_A_ROLE: (role_id) => `/api/v1/role/${role_id}`,
  GET_USER_PERMISSIONS: () => `/api/v1/module/accessible`,
  GET_USER_PROFILE: () => `/api/v1/user/my-profile`,
  UPDATE_USER_PROFILE: (user_id) => `/api/v1/user/my-profile/${user_id}`,
  ADD_A_FIELD: () => `/api/v1/fields`,
  UPDATE_A_FIELD: (schema_id) => `/api/v1/fields/${schema_id}`,
  DELETE_A_FIELD: (id) => `/api/v1/fields/${id}`,
  UPDATE_FIELD_SORT: (schema_id) => `/api/v1/fields/${schema_id}/sort`,
  GET_ALL_FIELDS: (schema_id) => `/api/v1/fields/${schema_id}`,
  UPDATE_FIELDS_VISIBILITY: (id) => `/api/v1/fields/${id}`,
  ADD_CONTENT: (schema_id) => `/api/v1/items/${schema_id}`,
  UPDATE_CONTENT: (schema_id, content_id) =>
    `/api/v1/items/${schema_id}/${content_id}`,
  GET_CONTENT: (schema_id, searchTerm, { page = 1, limit = 10 }) =>
    `/api/v1/items/${schema_id}/data?filter=${searchTerm}&page=${page}&limit=${limit}`,
  GET_SINGLE_CONTENT: (schema_id, content_id) =>
    `/api/v1/items/${schema_id}/${content_id}`,
  DELETE_CONTENT: (content_id) => `/api/v1/items/${content_id}`,
  GET_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  ADD_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  UPDATE_PRESET: (collection_id) => `/api/v1/presets/${collection_id}`,
  GET_LANGUAGE_OPTIONS: (limit = 1000) =>
    `/api/v1/translation-language?limit=${limit}`,
  UPSERT_TRANSLATIONS: (id) => `/api/v1/translations/${id}`,
  GET_TRANSLATIONS: (searchTerm, unique, page = 1, limit = 10) =>
    `/api/v1/translations?filter=${searchTerm}&unique=${unique}&page=${page}&limit=${limit}`,
  GET_SINGLE_TRANSLATION: (id) => `/api/v1/translations/${id}`,
  DELETE_TRANSLATIONS: () => `/api/v1/translations`,
  GET_TRANSLATIONS_BY_GROUP: (code) =>
    `/api/v1/translations/locale?code=${code}`,
  GET_COMMON_SYSTEM_TRANSLATIONS: () => `/api/v1/common/system-translations`,
  GET_ALL_FOLDERS: ({ pagination = "false" }) =>
    `/api/v1/folders?pagination=${pagination}`,
  CREATE_FOLDER: () => `/api/v1/folders`,
  UPDATE_FOLDER: (id) => `/api/v1/folders/${id}`,
  MOVE_FOLDER: () => `/api/v1/folders/move`,
  DELETE_FOLDER: (id) => `/api/v1/folders/${id}`,
  UPLOAD_FILE: () => `api/v1/files`,
  DELETE_FILE: () => `api/v1/files/delete`,
  GET_DISPLAY_IMAGE: (img_id, height = "", width = "") =>
    `api/v1/image/${img_id}?height=${height}&width=${width}`,
  GET_FILES: ({
    folder = "",
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
  }) =>
    `api/v1/files?folder=${folder}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}`,
  GET_FILE: (file_id) => `api/v1/files/${file_id}`,
  UPDATE_FILE: (file_id) => `api/v1/files/${file_id}`,
  UPSERT_SYSTEM_TRANSLATIONS: (id) => `/api/v1/system-translations/${id}`,
  GET_SYSTEM_TRANSLATIONS: (searchTerm, unique, page = 1, limit = 10) =>
    `/api/v1/system-translations?filter=${searchTerm}&unique=${unique}&page=${page}&limit=${limit}`,
  GET_SINGLE_SYSTEM_TRANSLATION: (id) => `/api/v1/system-translations/${id}`,
  DELETE_SYSTEM_TRANSLATIONS: () => `/api/v1/system-translations`,
  GET_CONFIGURATION: ({
    type = "",
    page = 1,
    limit = 10,
    descending = "true",
    sortBy = "createdAt",
    pagination = false,
    filter = "",
  }) =>
    `/api/v1/configuration?pagination=${pagination}&page=${page}&limit=${limit}&descending=${descending}&sortBy=${sortBy}&type=${type}&filter=${filter}`,
  ADD_CONFIGURATION: () => `/api/v1/configuration`,
  UPDATE_CONFIGURATION: ({ type }) => `/api/v1/configuration/${type}`,
  DELETE_CONFIGURATION: ({ type }) => `/api/v1/configuration/${type}`,
};

import React, { useEffect } from "react";
import { Flex } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IconsAll from "../../../Components/IconsAll";
import { setActiveTab } from "../../../Redux/slices/activeTab.reducer";
import SubMenu from "../../../Components/SubMenu";
import SystemTranslations from "./SystemTranslations";
import AddSystemTranslations from "./AddSystemTranslations";

const AdminSettings = () => {
  const { t } = useTranslation();
  const path = location.pathname.split("/");
  const activeTab = useSelector((state) => state.activeTab[path[1]]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subMenus = [
    {
      name: t("translations"),
      link: "/admin/settings/system-translations",
      icon: <IconsAll.VertalingenIcon />,
      divider: true,
    },
  ];

  useEffect(() => {
    if (activeTab && activeTab?.currentTab) {
      !path[4] && navigate(activeTab?.redirectionLink);
    } else {
      dispatch(
        setActiveTab({
          page: "adminSettings",
          tab: "System Translations",
          redirectionLink: "/admin/settings/system-translations",
        }),
      );
    }
  }, [path[3], activeTab]);

  const renderChild = () => {
    switch (path[3]) {
      case "system-translations":
        return path[4] ? <AddSystemTranslations /> : <SystemTranslations />;
      default:
        return <SystemTranslations />;
    }
  };

  return (
    <Flex className="main-content-wrap">
      <SubMenu
        isSubMenuLogo
        isSubMenuVersion
        items={subMenus}
        currentPage={path[2]}
      />
      {renderChild()}
    </Flex>
  );
};

export default AdminSettings;
